/* eslint-disable react-hooks/exhaustive-deps */
import { getPartnerLicenses } from "../components/license/license.service"
import { adminUserIds } from "../constants/navigation"
import { ILicense } from "../db/models"
import { dev_log } from "../utilities"
import { useAuth } from "./authContext"
import { createContext, useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"

interface Context {
  licenses: ILicense[] | null
  selectedLicense: ILicense | null
  setSelectedLicense: (license: ILicense) => void
  getSelectedLicense: () => Promise<ILicense>
  removeSelectedLicense: () => void
  fetchLicenses: () => void
}

const LicenseContext = createContext<Context>(null)

export function LicenseProvider({ children }) {
  const { user } = useAuth()
  const [cookies, setCookie, removeCookie] = useCookies([
    "selectedLicense",
    "impersonatedUser",
  ])
  const [licenses, setLicenses] = useState<ILicense[] | null>(null)
  const [selectedLicense, setSelected] = useState<ILicense | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedLicense = (license: ILicense) => {
    license &&
      license.id &&
      setCookie("selectedLicense", license.id, {
        path: "/",
        maxAge: 604800, // Expires after 7 days
      })
    setSelected(license)
  }

  async function getSelectedLicense() {
    const _selectedLicenseId = cookies.selectedLicense?.toString() || ""
    dev_log("selectedLicenseId:", _selectedLicenseId)
    if (licenses && _selectedLicenseId) {
      const _license = licenses.find(
        (license) => license.id === _selectedLicenseId
      )

      setSelectedLicense(_license)
      return _license
    }
    return null
  }

  const removeSelectedLicense = () => {
    removeCookie("selectedLicense", { path: "/" })
    dev_log("---- removing selected license ----")
    // dev_log("cookies", cookies)
    setSelected(null)
  }

  const fetchLicenses = async () => {
    dev_log("--- fetching licenses ----")

    const licenses = await getPartnerLicenses()
    setLicenses(licenses)
  }

  useEffect(() => {
    dev_log("USER", user)
    if (!user) return
    if (!licenses) fetchLicenses()
  }, [cookies.impersonatedUser, user, licenses])

  useEffect(() => {
    const isAdminAndImpersonating = Boolean(
      adminUserIds.includes(user?.id) && cookies.impersonatedUser
    )
    dev_log("licenses", licenses)
    if (licenses && licenses.length) {
      if (cookies.selectedLicense) {
        const license = licenses.find(
          (license) => license.id === cookies.selectedLicense
        )
        if (license) {
          setSelectedLicense(license)
        }
        // If:
        // - Admin is not impersonating
        // - The license is not found in the list of the user's licenses
        // -> Remove the cookie and reload the page
        else if (!isAdminAndImpersonating) {
          removeSelectedLicense()
          window.location.reload()
        }
      }
      //
      else {
        setSelectedLicense(licenses[0])
      }
    }
  }, [licenses])

  const value: Context = {
    licenses,
    selectedLicense,
    setSelectedLicense,
    getSelectedLicense,
    removeSelectedLicense,
    fetchLicenses,
  }

  return (
    <LicenseContext.Provider value={value}>{children}</LicenseContext.Provider>
  )
}

export function useLicenseContext() {
  return useContext(LicenseContext)
}
